import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { Divider, Skeleton, notification } from "antd";
import { ISeat, Schedule } from "../../types";
import { db } from "../../types/db";
import { getCookie } from "../../helpers/utils";
import { api } from "../../helpers/api";
import { RouteConstants } from "../../helpers/constants";
import { Spinner } from "@material-tailwind/react";

type Props = {};

interface TicketPrice {
  fare: number;
  serviceFee: number;
  amountPayable: number;
}

const TripSummary = (props: Props) => {
  const [seats, setSeats] = useState<ISeat[]>([]);

  const details: any = localStorage.getItem("details");
  const tripDetails = JSON.parse(details);

  //const ticket: any = localStorage.getItem("ticket");
  //const ticketDetails = JSON.parse(ticket);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [, setSchedule] = useState<Schedule | undefined>();
  const [priceDetails, setPriceDetails] = useState<TicketPrice>();
  const token = getCookie("oya_token");

  const navigate = useNavigate();
  const params = useParams();

  const handleSubmit = async () => {
    if (seats.length > 0) {
      try {
        setSubmitting(true);

        const payload = seats.map((item) => ({
          seat_id: item?.seat_id,
          pickup_id: item?.pickup_id && item?.pickup_id,
          schedule_id: item?.schedule_id,
          minors: item?.minors,
          luggage_description: item?.luggage_description
            ? item?.luggage_description
            : undefined,
          occupant_type: item?.occupant_type,
          passenger: {
            first_name: item?.passenger?.first_name,
            last_name: item?.passenger?.last_name,
            phone: item?.passenger?.phone,
            ice_phone: item?.passenger?.ice_phone,
          },
        }));
        // await db.seats.clear();

        // await db.transaction("rw", db.seats, async () => {
        //   await db.seats.bulkAdd(seats);
        // });
        const { data } = await api.post(
          RouteConstants.TICKETS,
          {
            tickets: payload,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setSchedule(data?.payload);

        navigate(`/trips/${params?.id}/payment`);
        localStorage.setItem("ticket", JSON.stringify(data?.payload));

        setSubmitting(false);
      } catch (error: any) {
        setSubmitting(false);
        notification.error({
          message: error?.response?.data?.message || error?.message,
        });
      }
    } else {
      notification.info({
        message: "Please select at least one seat to continue",
      });
    }
  };

  const getUserSeats = async () => {
    try {
      setLoading(true);
      const res = await db.seats.toArray();
      if (res.length > 0) {
        setSeats(res);

        let payload: any = {
          schedule_id: res[0]?.schedule_id,
          num_of_seats: res.length,
        };

        const { data } = await api.post(
          RouteConstants.GET_TICKET_PRICE,
          { ...payload },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setPriceDetails(data?.payload);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getUserSeats();

    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta
          name='apple-mobile-web-app-status-bar-style'
          content={"#B40303"}
        />
        <meta name='theme-color' content={"#fff"} />
      </Helmet>

      <div className='bg-[#fff] relative min-h-screen overflow-hidden'>
        <div className='top-0 fixed z-[100] w-full'>
          <div className='relative w-full inline-flex'>
            <div className='bg-white h-[44px] w-full pt-5 pb-3 px-[15px] '>
              <div className='flex items-center'>
                <div className='flex-[0.3]'>
                  <button onClick={() => navigate(-1)}>
                    <ChevronLeftIcon className='w-7 h-7 ' />
                  </button>
                </div>
                <div className='flex-[0.7]'>
                  <div className='header-text-3'>Trip summary</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=' mt-[7rem]'>
          {loading ? (
            <div className='px-5 rounded-lg'>
              <Skeleton paragraph active />
            </div>
          ) : (
            <div className=' min-h-screen overflow-y-auto'>
              <div className='px-5'>
                <div className='text-oya-gray font-semibold'>Trip details</div>
                <div className='flex w-full justify-between items-center mt-1'>
                  <div>Route</div>
                  <div>
                    {tripDetails?.route?.from?.name} to{" "}
                    {tripDetails?.route?.to?.name}
                  </div>
                </div>
                <div className='flex w-full justify-between items-center mb-1'>
                  <div>Date</div>
                  <div>{moment(tripDetails?.departures_at).format("LL")}</div>
                </div>
                <Divider className='border-oya-gray-100 my-2' />

                {seats?.map((item: ISeat) => (
                  <div key={item.index}>
                    <div className='flex justify-between items-center'>
                      <div className='flex items-center gap-1'>
                        <div className='text-oya-gray font-semibold '>
                          Passenger {item?.index + 1}
                        </div>
                        <div>
                          {item?.occupant_type === "LAGGAGE" && "(Luggage)"}
                        </div>
                      </div>
                      <button
                        onClick={() => navigate(-1)}
                        className='text-oya-red-50'
                      >
                        Edit
                      </button>
                    </div>

                    <div className='flex w-full justify-between items-center mt-1'>
                      <div>Seat number</div>
                      <div>{item?.seat_num}</div>
                    </div>
                    {item?.occupant_type !== "LAGGAGE" && (
                      <div className='flex w-full justify-between items-center mt-1'>
                        <div>Share with a minor</div>
                        <div>{item?.shareWithMinor ? "Yes" : "No"}</div>
                      </div>
                    )}
                    {item?.occupant_type !== "LAGGAGE" && (
                      <div className='flex w-full justify-between items-center mt-1'>
                        <div>Name</div>
                        <div>
                          {item?.passenger?.first_name}{" "}
                          {item?.passenger?.last_name}
                        </div>
                      </div>
                    )}
                    <div className='flex w-full justify-between items-center mt-1'>
                      <div>Number</div>
                      <div>{item?.passenger?.phone}</div>
                    </div>
                    {item?.occupant_type === "LAGGAGE" && (
                      <div className='flex w-full justify-between items-center mt-1'>
                        <div>Description</div>
                        <div className='text-right w-1/2'>
                          {item?.luggage_description}
                        </div>
                      </div>
                    )}
                    {item?.occupant_type !== "LAGGAGE" && (
                      <div className='flex w-full justify-between items-center mt-1'>
                        <div>Emergency contact</div>
                        <div>{item?.passenger?.ice_phone}</div>
                      </div>
                    )}
                    <Divider className='border-oya-gray-100 my-2' />
                  </div>
                ))}
                <div className='text-oya-gray font-semibold '>
                  Price summary
                </div>
                {/* {ticketDetails?.length > 0 && (
                  <>
                    {ticketDetails?.map((item: any, index: number) => (
                      <div className="border-b border-oya-gray-100 pb-2 mb-2">
                        <div className="flex w-full justify-between items-center mt-1">
                          <div>Amount</div>
                          <div>
                            {item?.price?.currency}
                            {Intl.NumberFormat("en-EN").format(
                              Number(item?.price?.amount)
                            )}
                          </div>
                        </div>
                        <div className="flex w-full justify-between items-center mt-1">
                          <div>Service charge</div>
                          <div>
                            {item?.service_fee?.currency}
                            {Intl.NumberFormat("en-EN").format(
                              Number(item?.service_fee?.amount)
                            )}
                          </div>
                        </div>
                        <div className="flex w-full justify-between items-center mt-1">
                          <div>Total</div>
                          <div>
                            {item?.amount_payable?.currency}
                            {Intl.NumberFormat("en-EN").format(
                              Number(item?.amount_payable?.amount)
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )} */}

                <div className='border-b border-oya-gray-100 pb-2 mb-2'>
                  <div className='flex w-full justify-between items-center mt-1'>
                    <div>Amount</div>
                    <div>
                      GHS
                      {Intl.NumberFormat("en-EN").format(
                        Number(priceDetails?.fare)
                      )}
                    </div>
                  </div>
                  <div className='flex w-full justify-between items-center mt-1'>
                    <div>Service charge</div>
                    <div>
                      GHS
                      {Intl.NumberFormat("en-EN").format(
                        Number(priceDetails?.serviceFee)
                      )}
                    </div>
                  </div>
                  <div className='flex w-full justify-between items-center mt-1'>
                    <div>Total</div>
                    <div>
                      GHS
                      {Intl.NumberFormat("en-EN").format(
                        Number(priceDetails?.amountPayable)
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <div className='fixed w-full bottom-0'>
                <div className='shadow-[0_30px_50px_15px_rgba(0,0,0,0.3)] py-5 px-7 rounded-t-[12px] bg-white'>
                  <div className='flex w-full justify-between items-center'>
                    <button
                      onClick={() => handleSubmit()}
                      className='bg-oya-red-100 rounded-lg w-full h-[42px] flex justify-center items-center text-white'
                    >
                      {submitting ? (
                        <Spinner />
                      ) : (
                        <div className='text-[16px]'>Book</div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default TripSummary;
