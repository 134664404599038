import { useEffect, useState, Fragment } from "react";
import PageLayout from "../components/utils/PageLayout";
import { useParams, useNavigate } from "react-router-dom";
import { getCookie } from "../helpers/utils";
import { ArrowBackIos } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import { api } from "../helpers/api";
import { RouteConstants } from "../helpers/constants";
import { MidRoute, Schedule } from "../types";
import PhoneInput from "react-phone-number-input";
import Loader from "../components/Loader";
import moment from "moment";
import {
  Spin,
  Form,
  InputNumber,
  Radio,
  Space,
  Divider,
  notification,
  Input,
  Modal,
} from "antd";
import Select from "react-select";
import CustomButton from "../components/CustomBtn";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import { NetworkProvider } from "../types";
import { getTransactionStatus } from "../helpers/utils";
import successImg from "../assets/img/success.gif";
import verifyImg from "../assets/img/verify.gif";
import loadingImg from "../assets/img/loading.gif";
import { PORTAL_URL } from "../helpers/constants";
import ReactGA from "react-ga";
import { XMarkIcon } from "@heroicons/react/24/outline";
import TripDetails from "../mobile/home/TripDetails";
import { useSelector } from "react-redux";
import { UserState } from "../redux/reducer";

interface Option {
  label: string;
  value: number;
  status?: number;
}

interface SelectType {
  label: string;
  value: number;
}

interface TicketPrice {
  fare: number;
  serviceFee: number;
  amountPayable: number;
}
type CustomSeatType = {
  id: number;
  number: number;
  status: number;
  selected: boolean;
};

const BookTrip = () => {
  const user_info = useSelector((state: UserState) => state);

  const [form] = Form.useForm();

  const [busSeats, setBusSeats] = useState<CustomSeatType[]>([]);
  const [schedule, setSchedule] = useState<Schedule | undefined>();
  const [seatsLoading, setSeatsLoading] = useState(false);
  const [selectedPickup, setSelectedPickup] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [isMobile, setIsMobile] = useState(true);

  const [selectedProvider, setSelectedProvider] = useState<any>();
  //const [phone, setPhone] = useState<string | undefined>();
  const [pay_phone, setPaymentPhone] = useState<string | undefined>();

  const [selectedMidRoute, setSelectedMidRoute] = useState<any>();
  const [seatArray, setSeatArray] = useState<any[]>([]);
  const [seatObjArray, setSeatObjArray] = useState<CustomSeatType[]>([]);

  const [pickups, setPickups] = useState<Option[]>([]);

  const [accordionOpen, setAccordionOpen] = useState(0);
  const [fetchingProviders, setFetchingProviders] = useState(false);
  const [networks, setNetworks] = useState<SelectType[]>([]);
  const [otp, setOtp] = useState("");
  // eslint-disable-next-line
  const [_, setInitiatePayment] = useState(false);
  //const [pin, setPin] = useState('');

  const [otp_required, setOTPRequired] = useState(false);
  const [submittingOTP, setSubmittingOTP] = useState(false);
  const [paymentId, setPaymentId] = useState<any>();

  //processed
  const [isBusy, setIsBusy] = useState(false);
  const [failed, setFailed] = useState(false);
  const [success, setSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [stilProcessing, setStillProcessing] = useState(false);

  const [countdown, setCountdown] = useState(60); // Initial countdown value
  const [isCounting, setIsCounting] = useState(false);
  const [priceDetails, setPriceDetails] = useState<TicketPrice>();
  const [loadingPrices, setLoadingPrices] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const token = getCookie("oya_token");
  const user = getCookie("oya_user_info");
  const usr = JSON.parse(user);

  const isLarge = useMediaQuery("(min-width:1024px)");
  const required = {
    0: ["minors", "pickup"],
  };

  const getTicketPrice = async () => {
    if (seatArray.length > 0) {
      setLoadingPrices(true);

      try {
        let payload: any = {
          schedule_id: schedule?.id,
          num_of_seats: seatArray.length,
        };
        if (selectedMidRoute) {
          payload.pickup_id = selectedMidRoute?.value;
        }
        const { data } = await api.post(
          RouteConstants.GET_TICKET_PRICE,
          { ...payload },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPriceDetails(data?.payload);
        setLoadingPrices(false);
      } catch (error) {
        setLoadingPrices(false);

        console.log(error);
      }
    }
  };

  useEffect(() => {
    getTicketPrice();

    // eslint-disable-next-line
  }, [schedule, selectedMidRoute, seatArray]);

  const getNetworkProviders = async () => {
    try {
      setFetchingProviders(true);
      const { data } = await api.get(RouteConstants.NETWORK_PROVIDER, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const res = data?.payload?.items?.map(
        (item: NetworkProvider, index: number) => ({
          label: item?.name,
          value: item?.code,
        })
      );
      setNetworks(res);

      if (token) {
        if (
          user_info.user?.payment_account_number &&
          user_info.user?.payment_account_provider
        ) {
          form.setFieldValue(
            "account_number",
            user_info.user?.payment_account_number
          );
          form.setFieldValue("account_name", user_info.user?.name);

          const findProvider = res?.find(
            (item: any) =>
              item?.value === user_info.user?.payment_account_provider
          );
          setSelectedProvider(findProvider);
          form.setFieldValue("account_provider", findProvider);
          setPaymentPhone(user_info.user?.payment_account_number);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setFetchingProviders(false);
    }
  };

  const getBusSeats = async () => {
    setSeatsLoading(true);
    try {
      const res = await api.get(
        `${RouteConstants.SCHEDULES}/${params?.id}/seats`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setBusSeats(
      //   res.data?.payload?.items?.map((item: Seat) => ({
      //     label: `Seat ${item?.number}`,
      //     value: item?.id,
      //     status: item?.status,
      //   }))
      // );
      setBusSeats(res.data?.payload?.items);
      setSeatsLoading(false);
    } catch (e) {
      console.log(e);
      setSeatsLoading(false);
    }
  };

  const getPickups = async (id: number) => {
    try {
      const filter = [
        {
          f: "route.id",
          o: "=",
          p: [id],
        },
      ];

      const res = await api.get(
        `${RouteConstants.MIDROUTES}?filters=${JSON.stringify(filter)}`
      );

      setPickups(
        res.data?.payload?.items?.map((item: MidRoute) => ({
          label: item?.pickup?.name,
          value: item?.pickup?.id,
        }))
      );
    } catch (e) {
      console.log(e);
    }
  };

  const getScheduleInfo = async () => {
    try {
      const res = await api.get(
        `${RouteConstants.SCHEDULES}/${params?.id}/details`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSchedule(res.data?.payload);
      if (res?.data?.payload?.mid_route === true) {
        getPickups(res.data?.payload?.route?.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSeatSelection = (seat: CustomSeatType, index: number) => {
    let newSeats = [...busSeats];

    const getSelectedSeat = newSeats.find((value) => value?.id === seat?.id);
    if (getSelectedSeat) {
      if (getSelectedSeat.selected === true) {
        newSeats[index].selected = false;
      } else {
        newSeats[index].selected = true;
      }
    }

    const findOne = seatArray.find((value) => value === seat?.id);

    const findSeatObj = seatObjArray.find((value) => value?.id === seat?.id);

    if (findSeatObj) {
      setSeatObjArray(
        seatObjArray.filter((selectedSeat) => selectedSeat?.id !== seat?.id)
      );
    } else {
      setSeatObjArray([...seatObjArray, seat]);
    }

    if (findOne) {
      setSeatArray(
        seatArray.filter((selectedSeat) => selectedSeat !== seat?.id)
      );
    } else {
      setSeatArray([...seatArray, seat?.id]);
    }
  };

  const submitOtp = async (paymentId: any) => {
    if (otp) {
      setSubmittingOTP(true);
      try {
        const { data } = await api.post(
          `${RouteConstants.TRANSACTIONS}/${paymentId}/authorize`,
          {
            credential: otp,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        notification.success({
          message: "OTP Verified",
          description: data.message,
          duration: 15,
        });
        setOTPRequired(false);
        setSubmittingOTP(false);
        setProcessing(true);

        getTransactionStatus(paymentId || data?.payload?.id)
          .then((data: any) => {
            const code = data?.status?.code;
            if (code === "SUCCESS") {
              //handleClosed();
              setSuccess(true);
              setProcessing(false);
              ReactGA.event({
                category: `${process.env.NODE_ENV} Registered User Buy Ticket`,
                action: "Buy ticket",
                label: "Payment successful",
              });

              //setIsBusy(false);
            } else if (code === "FAILED") {
              setFailed(true);
              setSuccess(false);
              setProcessing(false);

              //handleClosed();
            } else {
              setSuccess(false);
              setProcessing(false);
              setFailed(false);
              setStillProcessing(true);
            }
          })
          .catch((e) => {
            //console.log(e)
            setIsBusy(false);
            //setStillProcessing(false);

            notification.error({
              message: e?.response?.data?.message || e?.message,
            });
            //handleClosed();
          });
      } catch (e: any) {
        notification.error({
          message: e?.response?.data?.message || e?.message,
          duration: 15,
        });
        //handleClosed();
        setSubmittingOTP(false);
      }
    } else {
      notification.error({
        message: "Please ensure all required fields are entered",
      });
    }
  };

  const payForTicket = async (ticket_id: Array<number>) => {
    //const values = form.getFieldsValue();
    setInitiatePayment(true);
    try {
      const res = await api.post(
        RouteConstants.PAYMENTS,
        {
          account_provider: selectedProvider?.value,
          account_name: form.getFieldValue("account_name"),
          account_number: pay_phone,
          method: selectedProvider?.value,
          services: ticket_id,
          type: "DEBIT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPaymentId(res.data?.payload?.id);
      if (res.data?.payload?.auth?.type === "OTP") {
        setIsBusy(true);
        setOTPRequired(true);
        setInitiatePayment(false);
      } else {
        notification.info({
          message: "Payment Initiated. Waiting for approval...",
          duration: 15,
        });

        const socket = new WebSocket(
          `${res.data?.payload?.realtime?.endpoint}?token=${res.data?.payload?.realtime?.token}`
        );
        setIsBusy(true);
        setProcessing(true);
        setSubmitting(false);
        setIsCounting(true);

        // Connection opened
        socket.addEventListener("open", (event) => {
          console.log("socket connection open");
        });

        // Listen for messages
        socket.addEventListener("message", (event) => {
          const data = JSON.parse(event.data);

          if (data?.status?.code === "SUCCESS") {
            setSuccess(true);
            setProcessing(false);
            setIsCounting(false);
            socket.close();
          }

          if (data?.status?.code === "FAILED") {
            setFailed(true);
            setSuccess(false);
            setProcessing(false);
            setIsCounting(false);

            socket.close();
          }

          //console.log('Message from server ', event.data);
        });
      }
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.message || e?.message,
        duration: 15,
      });
      setSubmitting(false);
      //handleClosed();
      //navigate(-1);
    }
  };

  const createTicket = () => {
    if (seatArray.length <= 0) {
      notification.error({
        message: "Please select a seat to continue",
        duration: 10,
      });
    } else {
      form
        .validateFields(required[0])
        .then(async (v) => {
          console.log(v);
          try {
            setSubmitting(true);
            const values = form.getFieldsValue();
            console.log(values);

            const payload = seatArray.map((item, index) => {
              return {
                seat_id: item,
                pickup_id: selectedMidRoute
                  ? selectedMidRoute?.value
                  : undefined,
                schedule_id: Number(params?.id),
                minors: form.getFieldValue("minors") || 0,
                occupant_type: "ADULT",
                // passenger: {
                //   first_name: usr?.first_name,
                //   last_name: usr?.last_name,
                //   phone: usr?.phone,
                //   ice_phone: usr?.ice1_phone,
                // },
              };
            });

            const res = await api.post(
              RouteConstants.TICKETS,
              {
                tickets: payload,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            let tickets = res?.data?.payload?.map((item: any) => item?.id);
            await payForTicket(tickets);
          } catch (e: any) {
            //console.log(e);
            setSubmitting(false);
            notification.error({
              message: e?.response?.data?.message || e?.message,
            });
          }
        })
        .catch((e) => {
          notification.error({
            message: "Fill required fields to continue",
          });
        });
    }
  };

  const handleOpen = (value: any) =>
    setAccordionOpen(accordionOpen === value ? 0 : value);

  function Icon({ id, open }: any) {
    return (
      <svg
        className={`${
          id === open
            ? "transition delay-150 -rotate-90"
            : "transition delay-150"
        }  transition-transform`}
        xmlns='http://www.w3.org/2000/svg'
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
      >
        <path
          d='M26.5599 11.9333L17.8666 20.6267C16.8399 21.6533 15.1599 21.6533 14.1333 20.6267L5.43994 11.9333'
          stroke='#B40303'
          stroke-width='3'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    );
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    getNetworkProviders();
    getScheduleInfo();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", checkScreenSize);

    checkScreenSize();

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  useEffect(() => {
    getBusSeats();
    // getPickups();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let timer: any;
    if (isCounting && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setFailed(true);
      setProcessing(false);
      setSuccess(false);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isCounting, countdown]);

  return (
    <Fragment>
      {isMobile ? (
        <TripDetails />
      ) : (
        <PageLayout>
          <div className='pt-[4rem] px-[1rem] sm:px-[8rem] bg-white min-h-screen'>
            <Spin
              spinning={seatsLoading || submitting}
              className='py-10 md:py-20 min-h-[50vh] flex justify-center items-center'
              indicator={
                <Loader
                  float
                  bgColor='transparent appear'
                  width='w-16'
                  height='h-16'
                />
              }
            >
              {/* process modal */}
              {isBusy && (
                <Modal open={isBusy} footer={null} closable={false}>
                  {otp_required && (
                    <Form form={form} layout='vertical' className='appear'>
                      <div className='pb-4'>
                        <div className='font-semibold text-[20px] text-center'>
                          Verify Payment Mobile Number
                        </div>
                        <div>Enter OTP sent to your number</div>
                      </div>
                      <Form.Item
                        colon={false}
                        label='OTP'
                        name='otp'
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <div className='flex justify-center mt-4 mb-3'>
                          <img src={verifyImg} alt='verify' />
                        </div>
                        <Input
                          size='large'
                          disabled={submitting}
                          placeholder='000000'
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </Form.Item>
                      <div className='text-right flex items-end gap-3 justify-end'>
                        {submittingOTP ? (
                          <CustomButton
                            data-testid='submitPay'
                            disabled={submittingOTP}
                          >
                            <Loader width='w-6' height='h-6' />
                          </CustomButton>
                        ) : (
                          <CustomButton
                            data-testid='pay'
                            onClick={() => submitOtp(paymentId)}
                          >
                            Pay
                          </CustomButton>
                        )}
                        <CustomButton
                          data-testid='cancelPay'
                          variant='secondary'
                          onClick={() => {
                            setIsBusy(false);
                            setOTPRequired(false);
                            navigate(-1);
                          }}
                        >
                          Cancel
                        </CustomButton>
                      </div>
                    </Form>
                  )}
                  {processing && (
                    <div>
                      <div className='flex justify-center mt-4'>
                        {/* <img src={processingImg} alt='processing' /> */}
                        <div className='text-oya-yellow-500 text-center text-[24px] font-semibold'>
                          Processing payment
                        </div>
                      </div>
                      <div className='text-center py-3'>
                        You will receive a prompt to enter your Pin to complete
                        this transaction. You will lose your selected seat if
                        payment is not made before the count down.
                      </div>
                      <div className='text-center text-[20px] py-4'>
                        {countdown}
                      </div>
                      <Divider className='my-4' />
                      <div className='text-oya-yellow-500 text-center text-[24px] font-semibold'>
                        Processing...
                      </div>
                    </div>
                  )}

                  {success && (
                    <div>
                      <div className='w-full flex justify-end items-end'>
                        <button
                          data-testid='successBtn'
                          onClick={() => {
                            navigate(-1);
                            setIsBusy(false);
                            setSuccess(false);
                          }}
                        >
                          <XMarkIcon className='w-[20px] h-[20px] text-oya-gray' />
                        </button>
                      </div>
                      <div className='flex justify-center mt-4 items-center gap-3'>
                        <img src={successImg} alt='processing' />
                      </div>
                      <div className='text-center text-[24px] font-semibold text-seat-green py-1'>
                        Success
                      </div>
                      <div className='flex items-center gap-1'>
                        <div className='text-center'>
                          Your trip has been booked successfully. Access your
                          ticket
                        </div>
                        <div className='text-oya-red-50 font-semibold'>
                          <button
                            data-testid='accessTicketBtn'
                            onClick={() => {
                              window.open(`${PORTAL_URL}/trips`, "_blank");
                            }}
                          >
                            here
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {failed && (
                    <div>
                      <div className='w-full flex justify-end items-end'>
                        <button
                          data-testid='failedBtn'
                          onClick={() => {
                            navigate(-1);
                            setIsBusy(false);
                            setSuccess(false);
                          }}
                        >
                          <XMarkIcon className='w-[20px] h-[20px] text-oya-gray' />
                        </button>
                      </div>

                      <div className='flex items-center gap-3'>
                        <XMarkIcon className='w-12 h-12 text-red-500' />
                        <div className='text-center text-[24px] font-semibold text-red-500 py-1'>
                          Payment Failed
                        </div>
                      </div>
                      <div className='flex items-center gap-1'>
                        <div className='text-center py-3 text-lg'>
                          We could not process your payment. Try again later.
                        </div>
                      </div>
                      <CustomButton
                        data-testid='closePayment'
                        onClick={() => {
                          //setStillProcessing(false);
                          setIsBusy(false);
                          setFailed(false);
                          navigate(-1);
                        }}
                      >
                        Close
                      </CustomButton>
                    </div>
                  )}
                  {stilProcessing && (
                    <div>
                      <div className='w-full flex justify-end items-end'>
                        <button
                          data-testid='processBtn'
                          onClick={() => {
                            navigate(-1);
                            setIsBusy(false);
                            setSuccess(false);
                          }}
                        >
                          <XMarkIcon className='w-[20px] h-[20px] text-oya-gray' />
                        </button>
                      </div>
                      <div className='flex justify-center mt-4 items-center gap-3'>
                        <img src={loadingImg} alt='processing' />
                      </div>

                      <div className='flex justify-between items-center'>
                        <div className='text-center py-3 text-base'>
                          This is taking longer than expected. This could be as
                          a result of the payment provider's prompt failing.
                        </div>
                      </div>

                      <button
                        data-testid='dismissProcess'
                        className='text-oya-red-50 font-semibold hover:text-red-400'
                        onClick={() => {
                          //setStillProcessing(false);
                          setIsBusy(false);
                          setStillProcessing(false);
                          navigate(-1);
                        }}
                      >
                        Dismiss
                      </button>
                    </div>
                  )}
                </Modal>
              )}
              {token && user ? (
                <div>
                  <button
                    data-testid='goBack'
                    className='mt-[2rem] mb-5 flex gap-3 items-center'
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBackIos
                      sx={{
                        width: "20px",
                        height: "20px",
                        color: "#B40303",
                      }}
                    />
                    <div className='text-oya-red-100 font-semibold text-lg'>
                      Back
                    </div>
                  </button>

                  <Form layout='vertical' form={form}>
                    <div className='lg:flex flex-wrap gap-6'>
                      <div className='flex-[0.6] '>
                        {/* TripDetails */}
                        <Accordion
                          open={accordionOpen === 1}
                          icon={<Icon id={1} open={accordionOpen} />}
                          placeholder=''
                        >
                          <AccordionHeader
                            onClick={() => handleOpen(1)}
                            className='border-none'
                          >
                            <div className='flex items-center font-semibold text-oya-red-100 gap-5 text-[18px] sm:text-[24px]'>
                              <h2>1</h2>
                              <h2>Trip details</h2>
                            </div>
                          </AccordionHeader>
                          <AccordionBody className='overflow-auto'>
                            {accordionOpen === 1 && (
                              <div className='card-container font-medium text-[16px] mt-4 mb-8 lg:mx-8'>
                                <div className='flex justify-between font-semibold flex-wrap'>
                                  <div>
                                    <h1 className='tracking-tight'>
                                      {schedule?.route?.from?.name} -{" "}
                                      {schedule?.route?.to?.name}
                                    </h1>
                                    <p className='py-'>
                                      {moment(schedule?.departures_at).format(
                                        "MMMM Do, YYYY"
                                      )}
                                    </p>
                                  </div>
                                  <div>
                                    <div>
                                      <p>{schedule?.branch?.name}</p>
                                    </div>
                                    <div>
                                      <p>{schedule?.bus?.reg_number}</p>
                                      <p>
                                        {schedule?.price?.currency}{" "}
                                        {Intl.NumberFormat("en-US").format(
                                          Number(schedule?.price?.amount)
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <Form.Item
                                  name='minors'
                                  className='pt-3'
                                  label={
                                    <div className='text-sm text-oya-gray'>
                                      How many minors are travelling with you?
                                    </div>
                                  }
                                >
                                  <InputNumber min={0} defaultValue={0} />
                                </Form.Item>
                                <div>
                                  <Form.Item
                                    label='Where will you join the trip?'
                                    name='pickup'
                                    rules={[
                                      {
                                        required: true,
                                        message: "This field is required",
                                      },
                                    ]}
                                  >
                                    <Radio.Group
                                      size='large'
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        if (
                                          value === 0 &&
                                          form.getFieldValue("pickup_id")
                                        )
                                          form.setFieldsValue({
                                            pickup_id: null,
                                          });
                                        setSelectedPickup(value);
                                      }}
                                      data-testid='buy-ticket-pickup-radio-button'
                                    >
                                      <Space direction='vertical'>
                                        <Radio
                                          className='pickup__radio'
                                          value={0}
                                        >
                                          Bus Terminal
                                        </Radio>
                                        <Radio
                                          className='pickup__radio'
                                          value={1}
                                          disabled={pickups.length <= 0}
                                        >
                                          Mid Route
                                        </Radio>
                                      </Space>
                                    </Radio.Group>
                                    {pickups.length <= 0 && (
                                      <div className='text-oya-secondary-red'>
                                        This trip does not support joining
                                        midway.
                                      </div>
                                    )}
                                  </Form.Item>
                                  {selectedPickup === 1 && (
                                    <div className='w-full md:w-2/3'>
                                      <Form.Item
                                        colon={false}
                                        label={
                                          <div className='font-medium text-oya-ghana-header-h7'>
                                            Your pickup point
                                          </div>
                                        }
                                        name='pickup_id'
                                      >
                                        <Select
                                          options={pickups}
                                          onChange={(e) =>
                                            setSelectedMidRoute(e)
                                          }
                                        />
                                      </Form.Item>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </AccordionBody>
                        </Accordion>

                        <Divider className='border-oya-gray mt-2' />

                        {/* Seat selection */}
                        <Accordion
                          open={accordionOpen === 2}
                          icon={<Icon id={2} open={accordionOpen} />}
                          placeholder=''
                        >
                          <AccordionHeader
                            onClick={() => handleOpen(2)}
                            className='border-none'
                          >
                            <div className='flex items-center font-semibold text-oya-red-100 gap-5 text-[18px] sm:text-[24px]'>
                              <h2>2</h2>
                              <h2>Select Seat</h2>
                            </div>
                          </AccordionHeader>
                          <AccordionBody>
                            {accordionOpen === 2 && (
                              <div className='card-container mb-3 lg:mx-8'>
                                <div className=''>
                                  <Form.Item name='seat_id'>
                                    {!isLarge && (
                                      <div className='mb-4'>
                                        <div className='flex gap-5 items-center mb-3'>
                                          <div className='w-5 h-5 bg-seat-green' />
                                          <div>Available</div>
                                        </div>
                                        <div className='flex gap-2 items-center'>
                                          <div className='w-5 h-5 bg-oya-red-100' />
                                          <div>Unavailable</div>
                                        </div>
                                      </div>
                                    )}
                                    {/* <Checkbox.Group>
                                      <div className="flex gap-5">
                                        <div className="grid grid-cols-4">
                                          {busSeats.map((seat) => (
                                            <Checkbox
                                              key={seat?.id}
                                              value={seat}
                                              className="pt-2"
                                              disabled={seat?.status === 1}
                                              onChange={(e) => {
                                                handleSeatSelection(
                                                  e.target.value
                                                );
                                              }}
                                            >
                                              <div className=" relative flex items-center justify-center">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  className={`${
                                                    seat?.status === 1
                                                      ? "text-oya-red-100"
                                                      : "text-seat-green"
                                                  }`}
                                                  width="44"
                                                  height="39"
                                                  viewBox="0 0 54 49"
                                                  fill="none"
                                                >
                                                  <rect
                                                    y="6.23016"
                                                    width="5.25"
                                                    height="37.1907"
                                                    rx="2.625"
                                                    className="fill-current"
                                                    fill="none"
                                                  />
                                                  <rect
                                                    x="48.75"
                                                    y="6.23016"
                                                    width="5.25"
                                                    height="37.1907"
                                                    rx="2.625"
                                                    className="fill-current"
                                                    fill="none"
                                                  />
                                                  <path
                                                    d="M10.5 5.51144C10.5 2.95824 12.4242 0.806375 14.9672 0.579164C23.7952 -0.209571 30.1884 -0.180242 39.0114 0.591414C41.5634 0.814609 43.5 2.96993 43.5 5.53167V29.704C43.5 32.064 41.8493 34.1184 39.5261 34.5334C30.5267 36.1411 23.962 36.0686 14.5616 34.4894C12.1998 34.0926 10.5 32.0214 10.5 29.6265V5.51144Z"
                                                    className="fill-current"
                                                    fill="none"
                                                  />
                                                  <path
                                                    d="M10.5 42.7474C10.5 40.0977 13.0312 38.1936 15.6164 38.7748C23.981 40.6551 30.1751 40.6137 38.3947 38.766C40.9761 38.1858 43.5 40.0888 43.5 42.7347V43.8398C43.5 45.7559 42.1406 47.4108 40.2522 47.7352C30.5438 49.4033 23.8265 49.4414 13.7562 47.7303C11.8644 47.4089 10.5 45.7524 10.5 43.8335V42.7474Z"
                                                    className="fill-current"
                                                    fill="none"
                                                  />
                                                </svg>

                                                <span className="text-white text-oya-ghana-header-h7 absolute text-center pb-2">
                                                  {seat?.number}
                                                </span>
                                              </div>
                                            </Checkbox>
                                          ))}
                                        </div>
                                        {isLarge && (
                                          <div>
                                            <div className="flex gap-5 items-center mb-3">
                                              <div className="w-5 h-5 bg-seat-green" />
                                              <div>Available</div>
                                            </div>
                                            <div className="flex gap-2 items-center">
                                              <div className="w-5 h-5 bg-oya-red-100" />
                                              <div>Unavailable</div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </Checkbox.Group> */}
                                    <>
                                      <div className='sm:flex gap-5'>
                                        <div className='grid grid-cols-4 '>
                                          {busSeats.map((seat, index) => (
                                            <button
                                              key={seat?.id}
                                              //value={seat}
                                              className={`pt-2 m-2 ${
                                                seat?.selected
                                                  ? "bg-oya-red-20"
                                                  : ""
                                              }`}
                                              disabled={seat?.status === 1}
                                              onClick={(e) => {
                                                handleSeatSelection(
                                                  seat,
                                                  index
                                                );
                                              }}
                                            >
                                              <div className=' relative flex items-center justify-center'>
                                                <svg
                                                  xmlns='http://www.w3.org/2000/svg'
                                                  className={`${
                                                    seat?.status === 1
                                                      ? "text-oya-red-100"
                                                      : "text-seat-green"
                                                  }`}
                                                  width='44'
                                                  height='39'
                                                  viewBox='0 0 54 49'
                                                  fill='none'
                                                >
                                                  <rect
                                                    y='6.23016'
                                                    width='5.25'
                                                    height='37.1907'
                                                    rx='2.625'
                                                    className='fill-current'
                                                    fill='none'
                                                  />
                                                  <rect
                                                    x='48.75'
                                                    y='6.23016'
                                                    width='5.25'
                                                    height='37.1907'
                                                    rx='2.625'
                                                    className='fill-current'
                                                    fill='none'
                                                  />
                                                  <path
                                                    d='M10.5 5.51144C10.5 2.95824 12.4242 0.806375 14.9672 0.579164C23.7952 -0.209571 30.1884 -0.180242 39.0114 0.591414C41.5634 0.814609 43.5 2.96993 43.5 5.53167V29.704C43.5 32.064 41.8493 34.1184 39.5261 34.5334C30.5267 36.1411 23.962 36.0686 14.5616 34.4894C12.1998 34.0926 10.5 32.0214 10.5 29.6265V5.51144Z'
                                                    className='fill-current'
                                                    fill='none'
                                                  />
                                                  <path
                                                    d='M10.5 42.7474C10.5 40.0977 13.0312 38.1936 15.6164 38.7748C23.981 40.6551 30.1751 40.6137 38.3947 38.766C40.9761 38.1858 43.5 40.0888 43.5 42.7347V43.8398C43.5 45.7559 42.1406 47.4108 40.2522 47.7352C30.5438 49.4033 23.8265 49.4414 13.7562 47.7303C11.8644 47.4089 10.5 45.7524 10.5 43.8335V42.7474Z'
                                                    className='fill-current'
                                                    fill='none'
                                                  />
                                                </svg>

                                                <span className='text-white text-oya-ghana-header-h7 absolute text-center pb-2'>
                                                  {seat?.number}
                                                </span>
                                              </div>
                                            </button>
                                          ))}
                                        </div>
                                        {isLarge && (
                                          <div>
                                            <div className='flex gap-5 items-center mb-3'>
                                              <div className='w-5 h-5 bg-seat-green' />
                                              <div>Available</div>
                                            </div>
                                            <div className='flex gap-2 items-center'>
                                              <div className='w-5 h-5 bg-oya-red-100' />
                                              <div>Unavailable</div>
                                            </div>
                                            {/* <div>
                                            {selectedSeat && (
                                              <button
                                                data-testid='clearSeatSelection'
                                                className='text-oya-red-50 font-semibold mt-10'
                                                onClick={() =>
                                                  setSelectedSeat(undefined)
                                                }
                                              >
                                                Clear selection
                                              </button>
                                            )}
                                          </div> */}
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  </Form.Item>
                                </div>
                              </div>
                            )}
                          </AccordionBody>
                        </Accordion>

                        <Divider className='border-oya-gray mt-2' />
                        {/* Payment info */}
                        <Accordion
                          open={accordionOpen === 3}
                          icon={<Icon id={3} open={accordionOpen} />}
                          placeholder=''
                        >
                          <AccordionHeader
                            onClick={() => handleOpen(3)}
                            className='border-none'
                          >
                            <div className='flex mt-4 items-center font-semibold text-oya-red-100 gap-5 text-[24px]'>
                              <h2>3</h2>
                              <h2>Payment info</h2>
                            </div>
                          </AccordionHeader>
                          <AccordionBody>
                            {accordionOpen === 3 && (
                              <>
                                <div className='rounded-md border-oya-gray-100 border p-3 mt-5 mb-6 sm:mx-8'>
                                  <Form.Item
                                    colon={false}
                                    label='Choose Payment Network'
                                    name='account_provider'
                                    rules={[
                                      {
                                        required: true,
                                        message: "This field is required",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder='Your network provider'
                                      onChange={(e) => {
                                        // let findOne = networks.find(
                                        //   (value) => value?.code === e
                                        // );
                                        setSelectedProvider(e);
                                      }}
                                      options={networks}
                                      isSearchable
                                      isClearable
                                      isLoading={fetchingProviders}
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    colon={false}
                                    label='Mobile Money Number'
                                    name='account_number'
                                    rules={[
                                      {
                                        required: true,
                                        message: "This field is required",
                                      },
                                    ]}
                                  >
                                    <PhoneInput
                                      value={pay_phone}
                                      onChange={(e) => {
                                        let formatted = e?.replace(/\s+/g, "");
                                        setPaymentPhone(formatted);
                                      }}
                                      placeholder='0244123456'
                                      className='phone bg-white w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none'
                                      defaultCountry='GH'
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    colon={false}
                                    label='Mobile Money Name'
                                    name='account_name'
                                    rules={[
                                      {
                                        required: true,
                                        message: "This field is required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      size='large'
                                      placeholder='Full name'
                                    />
                                  </Form.Item>
                                </div>
                              </>
                            )}
                          </AccordionBody>
                        </Accordion>
                      </div>
                      <div className=' flex-[0.4] '>
                        <div className='card-container p-3 lg:fixed lg:w-[393px]'>
                          <div className='sm:text-lg mb-3 font-semibold'>
                            Ticket Summary
                          </div>
                          <div className='my-3'>
                            <div className='text-oya-red-50'>Trip details</div>
                            <div className='flex justify-between items-center'>
                              <div>Minor(s)</div>
                              <div>
                                {form.getFieldValue("minors")
                                  ? form.getFieldValue("minors")
                                  : 0}
                              </div>
                            </div>
                            <div className='flex justify-between items-center'>
                              <div>Seat Number(s)</div>
                              {seatObjArray?.length > 0 ? (
                                <div className='font-semibold flex gap-2 flex-wrap justify-end w-1/2 items-end text-right'>
                                  {seatObjArray?.map((item, index) => (
                                    <div key={index} className='flex'>
                                      <div>{item?.number}</div>
                                      <div>
                                        {index < seatObjArray?.length - 1
                                          ? ", "
                                          : ""}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div>No Seat selected</div>
                              )}
                            </div>
                            <div className='flex justify-between items-center'>
                              <div>Boarding</div>
                              <div>
                                {!selectedPickup ? "Bus Terminal" : "Mid-route"}
                              </div>
                            </div>
                          </div>
                          {/* Personal */}
                          <Divider className='border-oya-gray my-2' />
                          <div>
                            <div className='text-oya-red-50'>Personal info</div>
                            <div className='flex justify-between items-center'>
                              <div>Name</div>

                              <div>{usr?.name}</div>
                            </div>
                            <div className='flex justify-between items-center'>
                              <div>Phone</div>
                              <div>{usr?.phone}</div>
                            </div>
                            <div className='flex justify-between items-center'>
                              <div>Primary Emergency Contact</div>
                              <div>{usr?.ice1_phone}</div>
                            </div>
                          </div>
                          {/* Payment */}
                          <Divider className='border-oya-gray mt-2' />
                          <div>
                            <div className='text-oya-red-50'>
                              Payment details
                            </div>
                            <div className='flex justify-between items-center'>
                              <div>Payment provider</div>
                              <div>
                                {selectedProvider && (
                                  <div>{selectedProvider?.label}</div>
                                )}
                              </div>
                            </div>
                            <div className='flex justify-between items-center'>
                              <div>Payment number</div>
                              <div>{pay_phone && <div>{pay_phone}</div>}</div>
                            </div>
                            <div className='flex justify-between items-center'>
                              <div>Payment name</div>
                              <div>
                                {form.getFieldValue("account_name") && (
                                  <span>
                                    {form.getFieldValue("account_name")}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <Divider className='border-oya-gray my-2' />
                          <div className='my-3'>
                            <div className='flex justify-between items-center py-3'>
                              <div>Price</div>
                              {loadingPrices ? (
                                <div className='animate-pulse w-16 h-4 bg-slate-200' />
                              ) : (
                                <>
                                  {priceDetails && seatArray.length ? (
                                    <div>
                                      GHS
                                      {Intl.NumberFormat("en-EN").format(
                                        Number(priceDetails?.fare)
                                      )}
                                    </div>
                                  ) : (
                                    <div>-</div>
                                  )}
                                </>
                              )}
                            </div>
                            <div className='flex justify-between items-center mb-8'>
                              {!selectedPickup ? (
                                <div>Service fee</div>
                              ) : (
                                <div>Mid-route fee</div>
                              )}
                              {loadingPrices ? (
                                <div className='animate-pulse w-16 h-4 bg-slate-200' />
                              ) : (
                                <>
                                  {priceDetails && seatArray.length ? (
                                    <div>
                                      GHS
                                      {Intl.NumberFormat("en-EN").format(
                                        Number(priceDetails?.serviceFee)
                                      )}
                                    </div>
                                  ) : (
                                    <div>-</div>
                                  )}
                                </>
                              )}
                            </div>
                            <div className='flex justify-between items-center py-2'>
                              <div>Total</div>
                              {loadingPrices ? (
                                <div className='animate-pulse w-16 h-4 bg-slate-200' />
                              ) : (
                                <>
                                  {priceDetails && seatArray.length ? (
                                    <div>
                                      GHS
                                      {Intl.NumberFormat("en-EN").format(
                                        Number(priceDetails?.amountPayable)
                                      )}
                                    </div>
                                  ) : (
                                    <div>-</div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>

                          <CustomButton
                            data-testid='bookTrip'
                            width='w-full'
                            onClick={() => {
                              ReactGA.event({
                                category: `${process.env.NODE_ENV} Registered User Buy ticket`,
                                action: "Pay to book trip",
                              });

                              createTicket();
                            }}
                          >
                            Pay to book trip
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                  </Form>

                  {/* <div className='flex items-center gap-2 py-4'>
                <DirectionsBus />
                <div className='sm:text-xl'>Select Pickup Point</div>
              </div>
              <div className='w-[60%]'>
                <Select
                  options={busSeats}
                  isMulti
                  isOptionDisabled={(option) => option?.status !== 0}
                  onChange={(e) => console.log(e)}
                />
              </div> */}

                  {/* <div className='flex items-center flex-wrap'>
                <div className='flex-[0.6]'>
                  <BusSeats seats={busSeats} />
                </div>
                <div className='flex-[0.4] rounded-md bg-white p-4'>
                  <div>Stats</div>
                </div>
              </div> */}
                </div>
              ) : (
                <div>Schedule Not Available</div>
              )}
            </Spin>
          </div>
        </PageLayout>
      )}
    </Fragment>
  );
};

export default BookTrip;
